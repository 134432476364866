export const topNav = [
    {
        name: "Dashboard",
        path: "/dashboard"
    },
    {
        name: "Products",
        path: "/products"
    },
    {
        name: "Categories",
        path: "/categories"
    },
    {
        name: "Orders",
        path: "/orders"
    },
    {
        name: "Customers",
        path: "/customers"
    },
]